header {
    #header_data_profile {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        margin: 50px auto 75px auto;
        text-align: center;

        img#profile_image {
            width: 200px;
            height: 200px;
            margin-bottom: 30px;
        }
    
        h1 {
            font-size: 3rem;
            font-weight: 500;
            line-height: 80px;
        }
    
        h1 > span {
            color: #1e88e5;
        }

        h3 {
            font-weight: 400;
            letter-spacing: 0.5px;
        }
    }
}