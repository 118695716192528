p {
    font-weight: 300;
}

// COMPONENTE TITLE
#section_title {
    padding: 30px 0 40px 0;

    h2 {
        text-align: center;
        font-weight: 300;
    }

    hr {
        width: 30px;
        height: 1px;
        border: none;
        background-color: #000;
        margin: auto;
        margin-top: 10px;
    }
}

//COMPONENTE CARD
div.list_of_cards {
    max-width: 1440px;
    margin: 0 auto;
    padding: 25px 0 75px 0;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: stretch;
    align-items: stretch;

    .card {
        max-width: 450px;
        background-color: #f4f4f4;
        border: 1px solid rgba(0, 0, 0, 0.116);
        border-radius: 20px;
    }

    .card_content {
        padding: 25px 15px;

        h3 {
            font-weight: 400;
            letter-spacing: 0.5px;
        }

        p {
            font-size: 0.9rem;
            margin: 15px auto;
            text-align: justify;
            text-align-last: center;
        }
    }
    img {
        max-width: 100%;
        border-radius: 20px;
    }
}

@media screen and (max-width: 978px) {
    div.list_of_cards {
        justify-content: center;

        .card {
            max-width: 100%;
        }
    }
}

@media screen and (max-width: 1440px) {
    div.list_of_cards {
        .card{
            margin: 0 25px;
        }

    }
}