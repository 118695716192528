footer {
    padding: 10px 15px;
    background-color: #46C9FF;
    
    p {
        color: #f9f9f9;
        font-size: 13px;
        text-align: center;
        line-height: 1.3rem;
        letter-spacing: 0.25px;
    }
}