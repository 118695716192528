section#aboutme {
    padding-bottom: 100px;
    position: relative;
    background-color: #1e88e5;

    &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        // clip-path: polygon(100% 75%, 0% 100%, 100% 100%);
        clip-path: ellipse(50% 13% at 50% 100%);
        background-color: #fff;
    }

    #section_title {
        h2 {
            color: #fff;
        }

        hr {
            background-color: #fff;
        }
    }

    .section_content {
        padding: 30px 0 50px 0;
        text-align: center;

        p {
            width: 45%;
            margin: auto;
            color: #fff;
            line-height: 1.5rem;
            padding-bottom: 20px;

            b {
                letter-spacing: 0.25px;
            }
        }

        a {
            display: inline-block;
            margin-top: 15px;
            padding: 15px 30px;
            text-decoration: none;
            color: #000;
            background-color: #fff;
            border-radius: 12px;
        }
    }
}

@media screen and (max-width: 968px) {
    section#aboutme {
        .section_content {
            p {
                padding: 0 15px;
                max-width: 100%;
                width: 90%;
                padding-bottom: 20px;
            }
        }
    }
}