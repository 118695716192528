.skill-card {
    max-width: 350px;
    // border: 1px solid grey;
    // border-radius: 10px;

    .skill-icon {
        display: flex;
        justify-content: center;
        padding: 50px 0;

        img {
            width: 75px;
            height: 75px;
        }
    }

    .skill-content {

        h3 {
            font-weight: 500;
            text-align: center;
            letter-spacing: .1px;
        }

        p {
            padding: 25px 15px 50px 15px;
            text-align: center;
        }
    }
}