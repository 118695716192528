section#contact {
    background-color: #1e88e5;

    #section_title {
        h2 {
            color: #fff;
        }

        hr {
            background-color: #fff;
        }
    }

    .contact_content {
        width: 300px;
        margin: auto;
        padding-bottom: 30px;
        text-align: center;

        .small_icon {
            margin: 0 15px;
            width: 50px;
            height: 50px;
        }
    }
}