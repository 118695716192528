nav#navigation {
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    width: 90%;
    margin: auto;
    
    #navigation_menu_icon {
        display: none;
    }

    ul#navigation_menu {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;

        li a {
            padding: 0 30px;
            text-decoration: none;
            font-size: 14px;
            font-weight: 300;
            line-height: 60px;
            letter-spacing: 1px;
            color: #000;
            transition: all .2s;
        }

        li:not(:last-of-type) a {
            border-right: 1px solid rgba(0, 0, 0, 0.394);
        }

        li a:hover {
            font-weight: 500;
            font-size: 15px;
        }
    }
}

@media screen and (max-width: 678px) {
    nav#navigation {
        ul#navigation_menu {
            display: none;
        }

        // #navigation_menu_icon {
        //     width: 25px;
        //     height: 25px;
        //     display: block;
        //     margin: 25px;
        //     background-image: url('../../images/icons/50x50_MENU.svg');
        // }
    }
}