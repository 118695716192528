#skills {
    max-width: 1440px;
    margin: auto;

    #skills-cards {
        padding: 25px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
    }
}

@media screen and (max-width: 1440px) {
    #skills-cards {
        margin: 0 25px;
    }
}
